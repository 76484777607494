/* globals dataLayer */
import $ from 'jquery';
import Utils from './../utils';
import { scrollToElement } from '../utils/scroll-to-element';
import { showButtonLoader } from '../utils/button-loader';
import GaTracking from '../core/components/ga-tracking';
export default class CheckOut {
    static deleteItemBtn = '.js__checkout-summary-delete-item-btn';
    static loading = false;
    static currentDeliveryMethod;
    static deliveryFieldSelectors = ['.js__address-form', '.js__address-id-input'];
    static storeFieldSelectors = ['.js__store-inputs'];
    static $deliveryInputsSwitch = $('.js__delivery-method');
    static $tradeInCheckout = $('.js__trade-in-checkout');
    static $checkoutSubmitButton = $('.js__checkout-address-submit');
    static $deliveryMethodSelect = $('.js__delivery-method-select');
    static init() {
        this.enableSubmitButtonWhenOnlyOptionIsSelected();
        this.initDeliveryMethodRadioChangeActions();
        this.initDeliveryTabsActions();
        this.initCartDeleteActions();
        this.initOrderSubmitToForcePrivacyPolicyCheck();
        this.initAddressSubmitActions();
        this.initSubmitButtonDisablingActions();
        this.initCouponCodeActions();
        this.initStoreAndResellerChangeWithArrows();
    }
    static initCouponCodeActions() {
        $('body').on('change', '.js__coupon-input', e => {
            GaTracking.pushDataLayer({
                event: 'gtmZoneEvent',
                eventAction: 'Enter code',
                eventCategory: 'Checkout interactions',
                eventLabel: $(e.currentTarget).val(),
                eventNonInteraction: false,
            });
        });
    }
    static initSubmitButtonDisablingActions() {
        this.$deliveryInputsSwitch.each((index, element) => {
            if ($(element).is(':checked')) {
                this.$checkoutSubmitButton.prop('disabled', false);
            }
        });
        this.$deliveryInputsSwitch.on('change', e => {
            if ($(e.currentTarget).val()) {
                this.$checkoutSubmitButton.prop('disabled', false);
            }
        });
    }
    static enableSubmitButtonWhenOnlyOptionIsSelected() {
        const $deliveryMethodSelect = this.$deliveryMethodSelect;
        const $deliveryMethodSelectInput = $deliveryMethodSelect.find('input');
        if ($deliveryMethodSelect.length == 1 && $deliveryMethodSelectInput.is(':checked')) {
            this.$checkoutSubmitButton.prop('disabled', false);
        }
    }
    static initDeliveryMethodRadioChangeActions() {
        $('.js__delivery-method-select')
            .find('input')
            .on('change', e => {
            const $currentTarget = $(e.currentTarget);
            const deliveryValue = $currentTarget.closest('.js__delivery-method-select').data('delivery-method');
            if ($currentTarget.is(':checked')) {
                const $form = $(e.currentTarget).closest('form');
                $(`.js__delivery-method input[value="${deliveryValue}"]`).prop('checked', true).trigger('change');
                this.pushCheckoutOptionEvent(deliveryValue, $form);
            }
        });
    }
    static initDeliveryTabsActions() {
        Utils.toggleFields(this.$deliveryInputsSwitch.find('input:checked').val(), this.deliveryFieldSelectors, 'd');
        Utils.toggleFields(this.$deliveryInputsSwitch.find('input:checked').val(), this.storeFieldSelectors, 's');
        $('.js__delivery-method input').on('change', () => {
            const val = $('.js__delivery-method').find('input:checked').val();
            if (this.currentDeliveryMethod != val) {
                this.currentDeliveryMethod = val;
                Utils.toggleFields(val, this.deliveryFieldSelectors, 'd');
                Utils.toggleFields(val, this.storeFieldSelectors, 's');
            }
        });
    }
    static initCartDeleteActions() {
        $(this.deleteItemBtn).on('click', e => {
            if (!this.loading) {
                this.deleteItem(e);
            }
        });
    }
    static initOrderSubmitToForcePrivacyPolicyCheck() {
        const $policyAgreementCheckbox = $('.js__policy-agreement-checkbox');
        const $policyAgreementCheckboxError = $('.js__policy-agreement-error');
        const $checkoutSubmitButton = $('.js__checkout-submit-btn');
        const $form = $checkoutSubmitButton.closest('form');
        const $couponInput = $('.js__coupon-input');
        $checkoutSubmitButton.attr('type', 'button');
        $policyAgreementCheckbox.on('change', e => {
            const $checkedCheckboxes = this.getAllSelectedCheckboxes($policyAgreementCheckbox);
            $(e.currentTarget).removeClass('is-invalid').parent().removeClass('is-invalid');
            if ($policyAgreementCheckbox.length === $checkedCheckboxes.length) {
                const $couponInput = $('.js__coupon-input');
                const isCouponInputInvalid = $couponInput.length && $couponInput.val();
                $checkoutSubmitButton.attr('type', isCouponInputInvalid ? 'button' : 'submit');
                $checkoutSubmitButton.prop('disabled', false);
                $checkoutSubmitButton.removeClass('js__ignore-ajax-modal');
                $policyAgreementCheckboxError.hide();
            }
            else {
                $checkoutSubmitButton.attr('type', 'button');
                $checkoutSubmitButton.prop('disabled', true);
                $checkoutSubmitButton.addClass('js__ignore-ajax-modal');
            }
        });
        $couponInput.on('change', e => {
            $policyAgreementCheckbox.trigger('change');
        });
        $checkoutSubmitButton.on('click', () => {
            $policyAgreementCheckbox.trigger('change');
            const $couponInput = $('.js__coupon-input');
            const $checkedCheckboxes = this.getAllSelectedCheckboxes($policyAgreementCheckbox);
            if ($couponInput.length && $couponInput.val()) {
                this.addNotSubmittedCouponError($couponInput);
                scrollToElement($couponInput);
            }
            else if ($policyAgreementCheckbox.length !== $checkedCheckboxes.length) {
                $policyAgreementCheckbox.addClass('is-invalid').parent().addClass('is-invalid');
                $policyAgreementCheckboxError.fadeIn();
                scrollToElement($policyAgreementCheckbox);
            }
        });
        $form.on('submit', () => {
            showButtonLoader($checkoutSubmitButton);
            this.submitConfirmStepDataLayerEvents();
        });
    }
    static getAllSelectedCheckboxes($checkboxes) {
        return $checkboxes.filter((e, element) => {
            return $(element).is(':checked');
        });
    }
    static initAddressSubmitActions() {
        $('.js__checkout-address-submit').on('click', () => {
            const $couponInput = $('.js__coupon-input');
            if ($couponInput.length && $couponInput.val()) {
                this.addNotSubmittedCouponError($couponInput);
                scrollToElement($couponInput);
            }
            else if ($('.js__checkout-address-form').valid()) {
                this.submitAddressStepDataLayerEvents();
                $('.js__checkout-address-form').trigger('submit');
            }
            else {
                this.submitErrorDataLayerEvents($('.js__checkout-address-form'));
            }
        });
    }
    static addNotSubmittedCouponError($couponInput) {
        if (!$('.js__not-submitted-coupon').length) {
            const errorMessage = `<div class="js__not-submitted-coupon invalid-feedback"><div>${$couponInput.data('not-submitted-error-message')}</div></div>`;
            const $inputWrapper = $couponInput.parent();
            $couponInput.addClass('is-invalid');
            $inputWrapper.find('label').addClass('is-invalid');
            $inputWrapper.find('.invalid-feedback').remove();
            $inputWrapper.append(errorMessage);
        }
    }
    static pushCheckoutOptionEvent(val, $form) {
        let deliveryChangeEvent = $form.data('datalayer-on-delivery-change') || {
            event: 'gtmZoneEvent',
            eventAction: 'Delivery selection',
            eventCategory: 'Checkout interactions',
            eventLabel: val,
            eventNonInteraction: false,
        };
        deliveryChangeEvent = {
            ...deliveryChangeEvent,
            ...{ eventLabel: deliveryChangeEvent.eventLabel.replace('%val%', val) },
        };
        let option = '';
        if (val === 'd') {
            option = 'Delivery by courier';
        }
        else if (val === 's') {
            option = 'Receive at IQOS Store';
        }
        else {
            option = 'Receive at Reseller';
        }
        GaTracking.pushDataLayer(deliveryChangeEvent);
        GaTracking.pushDataLayer({
            event: 'checkoutOption',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 2,
                        option,
                    },
                },
            },
        });
    }
    static deleteItem(e) {
        const $currentTarget = $(e.currentTarget);
        const url = $currentTarget.data('url');
        const token = $currentTarget.data('csrf-token');
        const total = $currentTarget.data('total');
        const subTotal = $currentTarget.data('subtotal');
        const newTotal = Math.abs(parseFloat(subTotal) - parseFloat(total));
        const fullPrice = Math.abs(newTotal / 100).toFixed(2);
        const cartItemCount = Number($currentTarget.data('cart-items-count'));
        const $checkoutWrapper = $('.js__checkout-wrapper');
        $.ajax({
            type: 'DELETE',
            url: url,
            data: { _csrf_token: token },
            beforeSend: () => {
                this.loading = true;
                $currentTarget.find('img').addClass('d-none');
                Utils.contentLoader($checkoutWrapper, true);
            },
            error: () => {
                $currentTarget.find('img').removeClass('d-none');
                Utils.contentLoader($checkoutWrapper, false);
            },
            success: () => {
                const cartCurrentItemCount = cartItemCount - 1;
                this.removeDataEvent(e);
                window.location.replace($(e.currentTarget).data('redirect'));
                $(this.deleteItemBtn).each((index, item) => {
                    $(item).attr('data-total', newTotal);
                    $(item).attr('data-cart-items-count', cartCurrentItemCount);
                });
                $('.js__summary-total').text(fullPrice + ' €');
            },
            complete: () => {
                this.loading = false;
            },
        });
    }
    static removeDataEvent(e) {
        GaTracking.pushDataLayer({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: 'EUR',
                remove: {
                    products: [
                        {
                            name: $(e.currentTarget).data('name'),
                            id: $(e.currentTarget).data('id'),
                            price: $(e.currentTarget).data('price'),
                            brand: 'IQOS',
                            category: $(e.currentTarget).data('category') ? $(e.currentTarget).data('category') : '',
                            variant: $(e.currentTarget).data('variant') ? $(e.currentTarget).data('variant') : '',
                            quantity: $(e.currentTarget).data('quantity') ? $(e.currentTarget).data('quantity') : '',
                            dimension51: '',
                            dimension52: 'In Stock',
                            dimension54: '',
                            dimension55: '',
                            metric11: $(e.currentTarget).data('total-price') ? $(e.currentTarget).data('total-price') : '',
                        },
                    ],
                },
            },
        });
    }
    static submitAddressStepDataLayerEvents() {
        if (this.$tradeInCheckout.length) {
            GaTracking.pushDataLayer({
                event: 'TRADEIN_NEW',
                clickPlace: 'place_order_click',
                checkoutStep: '2',
                acquisitionPlace: this.$tradeInCheckout.data('trade-in-referer'),
            });
        }
    }
    static submitConfirmStepDataLayerEvents() {
        if (this.$tradeInCheckout.length) {
            GaTracking.pushDataLayer({
                event: 'TRADEIN_NEW',
                clickPlace: 'place_order_click',
                checkoutStep: '3',
                acquisitionPlace: this.$tradeInCheckout.data('trade-in-referer'),
            });
        }
    }
    static submitErrorDataLayerEvents($form) {
        if ($form.length && $form.data('datalayer-on-error')) {
            const validator = $form.validate();
            GaTracking.pushDataLayer({
                ...$form.data('datalayer-on-error'),
                eventLabel: `Error - ${validator.errorList[0]?.message}`,
            });
        }
    }
    static initStoreAndResellerChangeWithArrows() {
        const $storeRadio = $('.js__radio-input-with-description[name="iqos-stores"]');
        const $firstResellerRadio = $('.js__reseller-options').find('.js__radio-input-with-description').first();
        const $lastResellerRadio = $('.js__reseller-options').find('.js__radio-input-with-description').last();
        $storeRadio.on('keydown', e => {
            if (e.key === 'ArrowUp') {
                this.addFocusToRadio($lastResellerRadio);
            }
            if (e.key === 'ArrowDown') {
                this.addFocusToRadio($firstResellerRadio);
            }
        });
        $('body').on('keydown', '.js__reseller-options .js__radio-input-with-description:first', e => {
            if (e.key === 'ArrowUp') {
                this.addFocusToRadio($storeRadio);
            }
        });
        $('body').on('keydown', '.js__reseller-options .js__radio-input-with-description:last', e => {
            if (e.key === 'ArrowDown') {
                this.addFocusToRadio($storeRadio);
            }
        });
    }
    static addFocusToRadio($radio) {
        if (!$radio.length) {
            return;
        }
        setTimeout(() => {
            $radio.prop('checked', true);
            $radio.trigger('focus');
            $radio.trigger('change');
        });
    }
}
